import Vue from 'vue'
import VueRouter from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useJobStore } from '@/stores/job'
import Jobs from '@/views/Jobs.vue'
import Login from '@/views/Login.vue'
import NewJob from '@/views/NewJob.vue'
import UpcomingJob from '@/views/UpcomingJob.vue'
import ActiveJob from '@/views/ActiveJob.vue'
import CompletedJob from '@/views/CompletedJob.vue'
import Message from '@/views/Message.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/jobs'
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: Jobs
  },
  {
    path: '/new-job/:id',
    name: 'new-job',
    component: NewJob
  },
  {
    path: '/upcoming-job/:id',
    name: 'upcoming-job',
    component: UpcomingJob
  },
  {
    path: '/active-job/:id',
    name: 'active-job',
    component: ActiveJob
  },
  {
    path: '/completed-job/:id',
    name: 'completed-job',
    component: CompletedJob
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/message',
    name: 'message',
    component: Message
  },
]

const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE,
  base: process.env.BASE_URL,
  routes
})

export default router

router.beforeEach((to, from, next) => {
  // Redirect to login page if the user tries to access a page that requires them to be logged in
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const authStore = useAuthStore()
  const jobStore = useJobStore()

  if (authRequired && !authStore.authenticated) {
    return next("/login")
  }

  // Clear the errors in state
  authStore.clearAuthErrors();
  jobStore.clearJobErrors();

  next()
})
