<template>
  <div class="completedjob">
    <TheHeader title="Completed Job"></TheHeader>
    <v-container v-if="job" fill-height fluid>
      <v-row>
        <v-col>
          <v-card flat>
            <v-card-subtitle class="px-0">
              <v-chip :ripple="false" color="secondary">{{ job.id }}</v-chip>
              <v-chip :ripple="false" class="borderless pl-0" outlined>
                <div class="mx-1 passenger-count-icon">
                  <span class="secondary--text">{{ job.passengers }}</span>
                  <v-icon class="secondary--text">mdi-account</v-icon>
                </div>
                <v-icon class="mx-1 secondary--text" v-if="job.hasLuggage">mdi-briefcase</v-icon>
              </v-chip>
            </v-card-subtitle>
            <v-card-text class="px-0">
              <v-row>
                <v-col cols="4" class="secondary--text">Time</v-col>
                <v-col cols="8">{{ job.timeStart | humanizeDateTime | substring }} - {{ job.timeEnd | humanizeDateTime | substring }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="secondary--text">From</v-col>
                <v-col cols="8">{{ job.pickup.name }}, {{ job.pickup.address }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="secondary--text">
                  <span v-if="job.type === 'T'">Tour</span>
                  <span v-else>To</span>
                </v-col>
                <v-col cols="8">
                  <span v-if="job.type === 'T'">{{ job.tour.name }}</span>
                  <span v-else>{{ job.dropoff.name }}, {{ job.dropoff.address }}</span>
                </v-col>
              </v-row>
              <v-row v-if="job.requiresPayment">
                <v-col cols="4" class="secondary--text">Amount</v-col>
                <v-col cols="8">{{ job.amountReceived | currency }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="secondary--text">Trip Mileage</v-col>
                <v-col cols="8">{{ mileage }} km</v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="secondary--text">Description</v-col>
                <v-col cols="8" class="pre-formatted">{{ job.description }}</v-col>
              </v-row>
              <v-row v-if="jobCanBeEdited">
                <v-col>
                  <v-btn  :to="{name: 'active-job', params: {id: job.id}}" :hover="true" elevation="2" class="success" block>
                    Edit Job
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col class="error--text">
                  This Job can no longer be edited in JoDA
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <JobDetails :job="job" :hidden="true"></JobDetails>
        </v-col>
      </v-row>
    </v-container>
    <ErrorSnackbar :errors="errors" :timeout="this.$messageTimeout"></ErrorSnackbar>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import ErrorSnackbar from '@/components/BaseSnackbar.vue'
import JobDetails from '@/components/JobDetails.vue'
import { mapState } from 'pinia'
import { useJobStore } from '../stores/job'

export default {
  name: 'Jobs',
  data: () => ({
    job: null,
  }),
  components: {
    TheHeader,
    ErrorSnackbar,
    JobDetails,
  },
  computed: {
    ...mapState(useJobStore, {
      errors: (store) => store.errors
    }),
    mileage() {
      return this.job.mileEnd - this.job.mileStart
    },
    jobCanBeEdited() {
      // Determine if the driver can edit the job based on the job's end time and the current date and time
      let job = this.job
      let canEdit = null

      if (job) {
        let now = new Date
        let jobEndDate = new Date(this.job.closedAt)
        if (jobEndDate) {
          // The amount of time the job can be edited if it ended x minutes in the past
          let editLimit = new Date(now.getTime() - (parseInt(process.env.VUE_APP_EDIT_CLOSED_JOBS_INTERVAL) * 60000))

          // Only allow jobs which have been started and finished and are within the edit time limit to be edited.
          if (editLimit < jobEndDate && job.timeStart && job.timeEnd) {
            canEdit = true
          } else {
            canEdit = false
          }
        }
      }
      return canEdit
    }
  },
  created() {
    this.getJob()
  },
  methods: {
    async getJob() {
      const result = await useJobStore().getJob({
        jobId: this.$route.params.id
      });
      this.job = result.data;
    },
  },
  filters: {
    substring: function (value) {
      if (!value) {
        return ''
      }
      return value.substring(value.length - 8)
    }
  }
}
</script>
