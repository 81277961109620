<template>
  <div id="upcomingjob">
      <TheHeader title="Manage Upcoming Job"></TheHeader>
      <v-container v-if="jobCanStart" fill-height fluid>
        <v-row v-if="jobCanBeManaged">
          <v-col class="pa-0">
            <v-tabs v-model="tabs" background-color="transparent" grow>
              <v-tab href="#start">Start</v-tab>
              <v-tab href="#noshow">No Show</v-tab>
              <v-tab href="#problem">Problem</v-tab>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row v-if="jobCanBeManaged">
          <v-col>
            <v-chip :ripple="false" color="secondary" class="mb-5">{{ job.id }}</v-chip>
            <v-tabs-items v-model="tabs" background-color="light-grey">
              <!-- Start job form -->
              <v-tab-item value="start" eager>
                <p>Add the details below to start this job</p>
                <v-form v-model="startFormValid" id="startform" ref="startform" @submit.prevent="submit">
                  <v-text-field v-model="startTime" :value="startTime" label="Start Time" :rules="starTimeRules" type="time" class="mt-1" outlined></v-text-field>
                  <v-text-field v-model="mileStart" label="Mile Start" type="number" :rules="mileStartRules" outlined></v-text-field>
                  <v-btn type="submit" color="success" block> Start Job </v-btn>
                </v-form>
              </v-tab-item>

              <!-- Mark job as no show -->
              <v-tab-item value="noshow" eager>
                <p>Tap the button to mark this job as a now show and close it</p>
                <v-form id="noshowform" ref="noshowform" @submit.prevent="submit">
                  <v-btn type="submit" color="success" block> Mark as No Show </v-btn>
                </v-form>
              </v-tab-item>

              <!-- Problem form -->
              <v-tab-item value="problem" eager>
                <p>Please describe the problem encountered with this job</p>
                <v-form v-model="problemFormValid" id="problemform" ref="problemform" @submit.prevent="submit">
                  <v-textarea v-model="problem" :rules="problemRules" required :auto-grow="true" name="problem" label="Problem Description" outlined></v-textarea>
                  <v-btn type="submit" color="success" block> Report Problem </v-btn>
                </v-form>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <JobMessage heading="View-Only Mode" message="You can't start this job as the luggage driver. Once the main driver starts it, it will be moved to your active jobs list."></JobMessage>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="job">
            <JobDetails :job="job" :hidden="jobCanBeManaged"></JobDetails>
          </v-col>
        </v-row>

        <BaseConfirmationDialog
          :handler="dialogAttrs.handler"
          :show="dialog"
          @cancel="dialog=false"
          :heading="dialogAttrs.heading"
          :message="dialogAttrs.message"
          :confirm-button-text="dialogAttrs.confirmButtonText">
        </BaseConfirmationDialog>
      </v-container>
      <v-container v-if="jobCanStart === false">
        <v-row>
          <v-col>
            <JobMessage icon="mdi-clock" heading="Start Restricted" message="This job is not within the allowed time range to be started."></JobMessage>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="job">
            <JobDetails :job="job"></JobDetails>
          </v-col>
        </v-row>
      </v-container>
      <ErrorSnackbar :errors="errors" :timeout="this.$messageTimeout"></ErrorSnackbar>
  </div>
</template>

<script>
import BaseConfirmationDialog from '@/components/BaseConfirmationDialog.vue'
import TheHeader from '@/components/TheHeader.vue'
import ErrorSnackbar from '@/components/BaseSnackbar.vue'
import JobDetails from '@/components/JobDetails.vue'
import JobMessage from '@/components/JobMessage.vue'
import { mapState } from 'pinia'
import { useJobStore } from '../stores/job'
import { canManageJob, jobStatusCheckAndRedirect } from '@/services/driverService.js'

export default {
  name: 'UpcomingJob',
  data: () => ({
    job: null,
    tabs: null,
    dialog: false,
    dialogAttrs: {
      handler: null,
      heading: '',
      message: '',
      confirmButtonText: '',
    },
    startFormValid: false,
    problemFormValid: false,
    problem: '',
    noshow: 0,
    startTime: '',
    mileStart: '',
    starTimeRules: [
      v => !!v || 'A start time is required'
    ],
    mileStartRules: [
      v => !!v || 'Mile start is required'
    ],
    problemRules: [
      v => !!v || 'A problem description is required'
    ],
  }),
  components: {
    BaseConfirmationDialog,
    TheHeader,
    ErrorSnackbar,
    JobDetails,
    JobMessage,
  },
  computed: {
    ...mapState(useJobStore, {
      errors: (store) => store.errors
    }),
    jobCanStart() {
      // Determine if the driver is allowed to start the job based on the job's start date and time and the current date and time
      let job = this.job
      let canStart = null

      if (job) {
        let now = new Date
        let jobDate = new Date(this.job.date)
        if (jobDate) {
          // The upper limit is the date calculated as x hours in the future
          let upperLimit = new Date(now.getTime() + (parseInt(process.env.VUE_APP_RESTRICT_JOB_START_BEFORE) * 60 * 60 * 1000))

          // The lower limit is the date calculated as x hours in the past
          let lowerLimit = new Date(now.getTime() - (parseInt(process.env.VUE_APP_RESTRICT_JOB_START_AFTER) * 60 * 60 * 1000))

          if ((jobDate > lowerLimit) && (jobDate < upperLimit)) {
            canStart = true
          } else {
            canStart = false
          }
        }
      }
      return canStart
    },
    jobCanBeManaged() {
      return canManageJob(this.job)
    },
  },
  created() {
    this.getJob()
    this.setStartTime()
  },
  methods: {
    async getJob() {
      try {
        const result = await useJobStore().getJob({jobId: this.$route.params.id});
        this.job = result.data;

        // Redirect if the job status indicates it's no longer an upcoming job.
        jobStatusCheckAndRedirect(this.job)
      } catch (error) {
        console.error('Error while fetching job', error)
      }
    },
    setStartTime() {
      this.startTime =  (new Date()).toTimeString().substr(0,5)
    },
    async startJob() {
      this.$refs.startform.validate()
      if (this.startFormValid) {
        try {
          const result = await useJobStore().startJob({
            jobId: this.job.id,
            startTime: this.startTime,
            mileStart: this.mileStart,
          })

          this.$router.push({ name: 'message', params: { text: result.message, redirect: 'jobs?tab=active' }})
        } catch (error) {
          console.error('Error while starting job', error)
        }
      }
    },
    async markAsNoshow() {
      try {
        const result = await useJobStore().markAsNoshow({jobId: this.job.id});
        this.$router.push({ name: 'message', params: { text: result.message, redirect: 'jobs?tab=completed' }})

      } catch (error) {
        console.error('Error while marking job as no show', error)
      }
    },
    async reportProblem() {
      this.$refs.problemform.validate()
      if (this.problemFormValid) {
        try {
          const result = await useJobStore().reportProblem({
            jobId: this.job.id,
            problemDescription: this.problem,
          })

          this.$router.push({ name: 'message', params: { text: result.message, redirect: 'jobs?tab=completed' }})
        } catch (error) {
          console.error('Error reporting problem', error)
        }
      }
    },
    submit (form) {
      // Dynamically configure the confirmation dialog based on the form being submitted.
      switch(form.target.id) {
        case 'startform':
          this.$refs.startform.validate()
          if (this.startFormValid) {
            this.dialog = true
            this.dialogAttrs.handler = this.startJob
            this.dialogAttrs.heading = 'Start Job'
            this.dialogAttrs.message = 'Are you sure you want to start this job?'
            this.dialogAttrs.confirmButtonText = 'Start Job'
          }
          break

        case 'noshowform':
          this.dialog = true
          this.dialogAttrs.handler = this.markAsNoshow
          this.dialogAttrs.heading = 'Mark As No Show'
          this.dialogAttrs.message = 'Are you sure you want to mark this job as no show?'
          this.dialogAttrs.confirmButtonText = 'Mark As No Show'
          break

        case 'problemform':
          this.$refs.problemform.validate()
          if (this.problemFormValid) {
            this.dialog = true
            this.dialogAttrs.handler = this.reportProblem
            this.dialogAttrs.heading = 'Report Problem'
            this.dialogAttrs.message = 'Are you sure you want to report this job as problematic?'
            this.dialogAttrs.confirmButtonText = 'Report Problem'
          }
          break
      }
    },
  }
}
</script>
