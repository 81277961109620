import { defineStore } from 'pinia';
import ApiService from '@/services/apiService.js';

const state = () => ({
  currentJob: null,
  newJobs : [],
  upcomingJobs : [],
  activeJobs : [],
  completedJobs : [],
  errors: null,
})

const getters = {
  newJob: (state) => (jobId) => state.newJobs.find(job => job.id == jobId),
  upcomingJob: (state) => (jobId) => state.upcomingJobs.find(job => job.id == jobId),
  activeJob: (state) => (jobId) => state.activeJobs.find(job => job.id == jobId),
  completedJob: (state) => (jobId) => state.completedJobs.find(job => job.id == jobId),
}

const actions = {
  // Updated Actions
  getJob(data) {
    return new Promise(resolve => {
      // Get a single job
      ApiService.getJob(data)
        .then(response => {
          if (response.data.success) {
            this.setCurrentJob(response.data.data)
            resolve(response.data)
          } else {
            this.setErrors(response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            this.setErrors(error.response.data.messages)
          }
        })
    })
  },

  getNewJobs() {
    return new Promise(resolve => {
      // Get the new jobs for the driver
      ApiService.getNewJobs()
        .then(response => {
          if (response.data.success) {
            const data = response.data.data;
            this.setNewJobs(data.jobs)
            resolve(response.data)
          } else {
            this.setErrors(response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            this.setErrors(error.response.data.messages)
          }
        })
    })
  },

  getUpcomingJobs() {
    return new Promise(resolve => {
      // Get the upcoming jobs for the driver
      ApiService.getUpcomingJobs()
        .then(response => {
          if (response.data.success) {
            const data = response.data.data;
            this.setUpcomingJobs(data.jobs)
            resolve(response.data)
          } else {
            this.setErrors(response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            this.setErrors(error.response.data.messages)
          }
        })
    })
  },

  getActiveJobs() {
    return new Promise(resolve => {
      // Get the active jobs for the driver
      ApiService.getActiveJobs()
        .then(response => {
          if (response.data.success) {
            const data = response.data.data;
            this.setActiveJobs(data.jobs)
            resolve(response.data)
          } else {
            this.setErrors(response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            this.setErrors(error.response.data.messages)
          }
        })
    })
  },

  getCompletedJobs() {
    return new Promise(resolve => {
      // Get the completed jobs for the driver
      ApiService.getCompletedJobs()
        .then(response => {
          if (response.data.success) {
            this.setCompletedJobs(response.data.data.jobs);
            resolve(response.data)
          } else {
            this.setErrors(response.data.message);
          }
        })
        .catch(error => {
          if (error.response.data) {
            this.setErrors(error.response.data.messages);
          }
        })
    })
  },

  acceptJob(data) {
    return new Promise(resolve => {
      // Marks a job as accepted or declined by the driver
      ApiService.acceptJob(data)
        .then(response => {
          if (response.data.success) {
            resolve(response.data)
          } else {
            this.setErrors(response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            this.setErrors(error.response.data.messages)
          }
        })
    })
  },

  startJob(data) {
    return new Promise(resolve => {
      // Set a job as started
      data.status = 'started'
      ApiService.setJobCommencementStatus(data)
        .then(response => {
          if (response.data.success) {
            resolve(response.data)
          } else {
            this.setErrors(response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            this.setErrors(error.response.data.messages)
          }
        })
    })
  },

  markAsNoshow(data) {
    return new Promise(resolve => {
      // Mark a job as a no show
      data.status = 'noshow'
      ApiService.setJobCommencementStatus(data)
        .then(response => {
          if (response.data.success) {
            resolve(response.data)
          } else {
            this.setErrors(response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            this.setErrors(error.response.data.messages)
          }
        })
    })
  },
  reportProblem(data) {
    return new Promise(resolve => {
      // Mark a job as having a problem
      data.status = 'problem'
      ApiService.setJobCommencementStatus(data)
        .then(response => {
          if (response.data.success) {
            resolve(response.data)
          } else {
            this.setErrors(response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            this.setErrors(error.response.data.messages)
          }
        })
    })
  },
  finishJob(data) {
    return new Promise(resolve => {
      // Finishes an active job
      ApiService.finishJob(data)
        .then(response => {
          if (response.data.success) {
            resolve(response.data)
          } else {
            this.setErrors(response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            this.setErrors(error.response.data.messages)
          }
        })
    })
  },

  clearJobErrors() {
    this.resetErrors()
  },

  // Updated Mutations
  setCurrentJob(job) {
    this.currentJob = job
  },
  setNewJobs(jobs) {
    this.newJobs = jobs
  },
  setUpcomingJobs(jobs) {
    this.upcomingJobs = jobs
  },
  setActiveJobs(jobs) {
    this.activeJobs = jobs
  },
  setCompletedJobs(jobs) {
    this.completedJobs = jobs
  },
  setErrors(errors) {
    this.errors = null
    if (Array.isArray(errors)) {
      this.errors = errors
    } else {
      this.errors = [errors]
    }
  },
  resetErrors() {
    this.errors = null
  }
}

export const useJobStore = defineStore('job', {
  state,
  getters,
  actions,
})

export default useJobStore;
