<template>
  <v-snackbar :value="errors" :timeout="this.$messageTimeout" color="error">
    <div v-for="(error, key) in errors" :key="key">{{ error }}</div>
  </v-snackbar>
</template>

<script>
export default {
  name: 'ErrorSnackbar',
  props: {
    errors: {
      required: true
    },
    timeout: {
      type: Number,
      default: 6000
    }
  }
}
</script>
