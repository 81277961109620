<template>
  <div id="activejob">
    <TheHeader :title="title"></TheHeader>
    <v-container v-if="job" fill-height fluid>
      <v-row v-if="restrictedEdit">
        <v-col>
          <JobMessage icon="mdi-pencil-lock" heading="Restricted-Edit Mode" message="The luggage driver is only allowed to add a comment in the description."></JobMessage>
        </v-col>
      </v-row>
      <v-row v-if="jobCanBeManaged || restrictedEdit">
        <v-col>
          <v-chip :ripple="false" color="secondary" class="mb-5">{{ job.id }}</v-chip>
          <p>{{ helpText }}</p>
          <v-form v-model="valid" ref="finishform" @submit.prevent="submit">
            <v-text-field v-if="job.requiresPayment" v-model="amount"  :label="job.collectCash ? 'Money Collected' : 'Money Collected (Optional)'" type="number" min="0" name="amount" :rules="job.collectCash ? amountRules : []"  :class="restrictedEdit ? 'd-none' : ''" :readonly="restrictedEdit" :required="job.collectCash" outlined></v-text-field>
            <v-text-field v-model="passengers" label="Passenger Count" type="number" min="1" name="passengers" :rules="passengerRules"  :class="restrictedEdit ? 'd-none' : ''" :readonly="restrictedEdit" required outlined></v-text-field>
            <v-text-field v-model="mileEnd" label="Mile End" type="number" :min="mileEndMin" name="mileEnd" :rules="mileEndRules.concat(mileEndMinRule)"  :class="restrictedEdit ? 'd-none' : ''" :readonly="restrictedEdit" required outlined></v-text-field>
            <v-text-field v-model="endTime" :value="endTime" label="Finish Time" type="time" name="endTime" :rules="endTimeRules"  :class="restrictedEdit ? 'd-none' : ''" :readonly="restrictedEdit" required outlined></v-text-field>
            <v-textarea v-model="description" label="Description (Optional)" name="description" :auto-grow="true" outlined></v-textarea>
            <v-btn type="submit" color="success" block>{{ buttonText }}</v-btn>
          </v-form>
        </v-col>
      </v-row >
      <v-row v-else>
        <v-col>
          <JobMessage icon="mdi-eye" heading="View-Only Mode" message="You can't finish this job as the luggage driver. Once the main driver finishes it, it will be moved to your completed jobs list."></JobMessage>
        </v-col>
      </v-row >
      <v-row>
        <v-col v-if="job">
          <JobDetails :job="job" :hidden="jobCanBeManaged || restrictedEdit"></JobDetails>
        </v-col>
      </v-row>

      <BaseConfirmationDialog
        :handler="finishJob"
        :show="dialog"
        @cancel="dialog=false"
        :heading="buttonText"
        :message="`Are you sure you want to ${ job.finished ? 'update' : 'finish' } this job?`"
        :confirm-button-text="buttonText">
      </BaseConfirmationDialog>
    </v-container>
    <ErrorSnackbar :errors="errors" :timeout="this.$messageTimeout"></ErrorSnackbar>
  </div>
</template>

<script>
import BaseConfirmationDialog from '@/components/BaseConfirmationDialog.vue'
import TheHeader from '@/components/TheHeader.vue'
import ErrorSnackbar from '@/components/BaseSnackbar.vue'
import JobDetails from '@/components/JobDetails.vue'
import JobMessage from '@/components/JobMessage.vue'
import { canManageJob, jobStatusCheckAndRedirect } from '@/services/driverService.js'
import { mapState } from 'pinia'
import { useJobStore } from '@/stores/job'

export default {
  name: 'ActiveJob',
  data: () => ({
    job: null,
    valid: true,
    amount: null,
    passengers: null,
    mileEnd: null,
    endTime: '',
    description: '',
    dialog: false,
    title: 'Manage Active Job',
    helpText: 'Add the details below to complete this job',
    buttonText: 'Finish Job',
    amountRules: [
      v => (v && v >= 0) || 'Amount collected is required and must be 0 or more',
    ],
    passengerRules: [
      v => !!v || 'The amount of passengers is required',
      v => (v && v >= 1) || 'The passenger amount must be at least one',
    ],
    mileEndRules: [
      v => !!v || 'The mile end is required',
    ],
    endTimeRules: [
      v => !!v || 'The finish time is required',
    ],
  }),
  components: {
    BaseConfirmationDialog,
    TheHeader,
    ErrorSnackbar,
    JobDetails,
    JobMessage,
  },
  computed: {
    ...mapState(useJobStore, {
      errors: (store) => (store).errors
    }),
    mileEndMin() {
      return this.job.mileStart
    },
    mileEndMinRule() {
      return [this.mileEnd >= parseInt(this.mileEndMin) || `The mile end value must be at least ${this.mileEndMin}`]
    },
    jobCanBeManaged() {
      return canManageJob(this.job)
    },
    restrictedEdit() {
      return !this.jobCanBeManaged && this.job.finished
    }
  },
  created() {
    this.getJob()
    this.setEndTime()
  },
  methods: {
    async getJob() {
      try {
        const result = await useJobStore().getJob({jobId: this.$route.params.id});
        this.job = result.data;
        jobStatusCheckAndRedirect(this.job);

        if (this.job.timeEnd) {
          this.title = 'Update Job';
          this.helpText = 'Add the details below to update this job';
          this.buttonText = this.restrictedEdit ? 'Update Description' : 'Update Job';
          this.amount = this.job.amountReceived;
          this.passengers = this.job.passengers;
          this.mileEnd = this.job.mileEnd;
          this.endTime = new Date(this.job.timeEnd).toTimeString().slice(0,5);
        }

      } catch (error) {
        console.error('Get Job Failed', error)
      }
    },
    setEndTime() {
      if (!this.endTime) {
        this.endTime = (new Date()).toTimeString().slice(0,5)
      }
    },
    async finishJob() {
      this.$refs.finishform.validate();

      if (this.valid) {
        const result = await useJobStore().finishJob({
          jobId: this.job.id,
          collectCash: this.job.collectCash,
          amount: this.amount,
          passengers: this.passengers,
          mileEnd: this.mileEnd,
          endTime: this.endTime,
          description: this.description,
        });

        this.$router.push({ name: 'message', params: { text: result.message, redirect: 'jobs?tab=completed' }});
      }
    },
    submit () {
      this.$refs.finishform.validate()
      if (this.valid) {
        this.dialog = true
      }
    },
  }
}
</script>
