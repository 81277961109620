<template>
  <v-layout id="navbar">
    <v-app-bar color="success" dark>
      <v-app-bar-nav-icon @click="drawer=true"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav>
        <v-list-item>
          <v-list-item-avatar v-if="user.picture">
            <v-img :src="user.picture"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="title">{{ user.fullname }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav>
        <v-list-group :value="true" prepend-icon="mdi-briefcase">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>My Jobs</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="job in items.jobs" :key="job.text" :to="job.route" active-class="primary--text" exact>
            <v-list-item-icon>
              <v-icon></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="job.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-divider></v-divider>
      <v-list nav>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title @click="dialog=true">Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <BaseConfirmationDialog
      :handler="logout"
      :show="dialog"
      @cancel="dialog=false"
      heading="Logout"
      message="Are you sure you want to logout?"
      confirm-button-text="Logout">
    </BaseConfirmationDialog>
  </v-layout>
</template>

<script>
import { mapState } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import BaseConfirmationDialog from '@/components/BaseConfirmationDialog.vue'

export default {
  name: 'TheHeader',
  props: {
    title: {
      default: process.env.VUE_APP_TITLE,
      type: String,
    }
  },
  data: () => ({
    drawer: false,
    dialog: false,
    items: {
      jobs: [
        { text: 'New', route: '/jobs?tab=new'},
        { text: 'Upcoming', route: '/jobs?tab=upcoming'},
        { text: 'Active', route: '/jobs?tab=active'},
        { text: 'Completed', route: '/jobs?tab=completed'},
      ],
    }
  }),
  components: {
    BaseConfirmationDialog,
  },
  computed: {
    ...mapState (useAuthStore, {
      user: (store) => store.user
    })
  },
  methods: {
    async logout() {
      try {
        await useAuthStore().logout();
        this.$router.push('/login');
      } catch (error) {
        console.error('Logout Failed', error)
      }
    }
  }
}
</script>
