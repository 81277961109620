import VueCookies from 'vue-cookies'
import router from '@/router'
import { useAuthStore } from '@/stores/auth'

const TOKEN_KEY = 'joda_token'
const USER_KEY = 'joda_user'

export const getToken = () => {
  return VueCookies.get(TOKEN_KEY)
}

export const setToken = (token, expiry) => {
  VueCookies.set(TOKEN_KEY, token, expiry)
}

export const destroyToken = () => {
  VueCookies.remove(TOKEN_KEY)
}

export const getUser = () => {
  return VueCookies.get(USER_KEY)
}

export const setUser = (user, expiry) => {
  VueCookies.set(USER_KEY, user, expiry)
}

export const destroyUser = () => {
  VueCookies.remove(USER_KEY)
}

export const forceLogout = (message) => {
  const authStore = useAuthStore();
  authStore.logout()
  .then(() => {
    if (router.currentRoute.path !== '/login') {
      router.push('/login')
      .then(() => authStore.setPersistentMessages(message, true))
    } else {
      authStore.setPersistentMessages(message, true)
    }
  })
}

export default {
  setToken,
  getToken,
  destroyToken,
  setUser,
  getUser,
  destroyUser,
  forceLogout,
}
