import { defineStore } from 'pinia';
import ApiService from '@/services/apiService.js'
import AuthService from '@/services/authService.js'

const state = () => ({
  user: AuthService.getUser() ? AuthService.getUser() : {},
  token: AuthService.getToken() || '',
  errors: null,
  persistentMessages: null,
  authenticated: AuthService.getToken() ? true : false,
})

const actions = {
  authenticate(data) {
    return new Promise(resolve => {
      // Sign in the user via the API service and store the access token and user information
      ApiService.googleSignIn(data)
        .then(response => {
          if (response.data.success) {
            this.authSuccess(response.data.data)
            resolve(data)
          } else {
            this.setErrors(response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            this.setErrors(error.response.data.messages)
          }
        })
    })
  },
  handleLogout() {
    this.logout()
  },
  clearAuthErrors() {
    this.resetErrors()
  },

  // Mutations
  authSuccess({ user, token }) {
    this.authenticated = true
    this.user = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      fullname: user.fullname,
      email: user.email,
      picture: user.picture,
    }
    this.token = token.accessToken
    AuthService.setUser(this.user, token.expiresIn + 's')
    AuthService.setToken(this.token, token.expiresIn + 's')
  },
  logout() {
    this.authenticated = false
    this.user = {}
    this.token = ''
    this.errors = null
    this.persistentMessages = null
    this.showErrors = false
    AuthService.destroyToken()
    AuthService.destroyUser()
  },
  setErrors(errors) {
    this.errors = null
    if (Array.isArray(errors)) {
      this.errors = errors
    } else {
      this.errors = [errors]
    }
  },
  setPersistentMessages(messages) {
    this.persistentMessages = null
    if (Array.isArray(messages)) {
      this.persistentMessages = messages
    } else {
      this.persistentMessages = [messages]
    }
  },
  resetErrors() {
    this.errors = null
    this.persistentMessages = null
  }
}

export const useAuthStore = defineStore('auth', {
  state,
  actions,
})

export default useAuthStore
