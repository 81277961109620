import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import stores from './stores'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue-cookies'
import './helpers/filters.js'

Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.prototype.$messageTimeout = parseInt(process.env.VUE_APP_MESSAGE_TIMEOUT) * 1000

new Vue({
  router,
  stores,
  vuetify,
  render: h => h(App)
}).$mount('#app')
